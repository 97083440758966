// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
// NgRx
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
// Reducer
import * as fromDisplay from './display.reducer';
// State
import * as displayState from './display.state';
// Effects
import { DisplayStoreEffects } from './display.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatDialogModule,
    StoreModule.forFeature(displayState.displayFeatureKey, fromDisplay.reducer),
    EffectsModule.forFeature([DisplayStoreEffects]),
  ],
})
export class DisplayStoreModule {}
