// Angular imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// NgRx Imports
import { Store } from '@ngrx/store';
// decode the jwt
import jwt_decode, { JwtPayload } from 'jwt-decode';
// rxjs
import { map, tap } from 'rxjs';
// Store
import { TokenStoreSelectors } from '../../root-store';
// interfaces
import { TokenResponse } from '../../features/login/interfaces/token-response';
// Environment Specific
import { environment } from './../../../environments/environment';
// Services
import { HttpHeaderService } from './http-header.service';
import { TokenDetails } from './../interfaces/store/token-details';

@Injectable({ providedIn: 'root' })
export class JwtTokenService {
  tokens: TokenDetails | null = null;
  tokenRefreshUri = environment.baseUri + '/api/Auth/refresh';
  constructor(private store: Store, private http: HttpClient, private httpHeaderService: HttpHeaderService) {
    if (environment.showConsoleLogs) {
      console.log('JwtToken Service Loaded');
    }

    const tokens$ = this.store.select(TokenStoreSelectors.selectToken);
    tokens$.subscribe((tokens) => {
      this.tokens = tokens;
      if (this.tokens?.bearer) {
        this.httpHeaderService.setBearerToken(this.tokens?.bearer);
      }
    });
  }

  callRefreshTokenApi(tokenDetails: TokenDetails) {
    return this.http
      .post<any>(this.tokenRefreshUri, tokenDetails, {
        headers: this.httpHeaderService.getNoAuthHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called refreshTokenApi - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response?.body)
      );
  }

  getTokensAsResponse(): TokenResponse {
    const tokenResponseObj: TokenResponse = {
      auth: {
        bearer: this.tokens?.bearer || '',
        refresh: this.tokens?.refresh || '',
      },
    };

    return tokenResponseObj;
  }

  decodeToken(token: string) {
    return jwt_decode<JwtPayload>(token);
  }

  getTokensFromAppCode(): TokenDetails | null {
    return this.tokens;
  }

  isTokenInAppCodeAndValid(): boolean {
    if (this.tokens && !this.isTokenExpired(this.decodeToken(this.tokens.bearer))) {
      return true;
    }
    return false;
  }

  isTokenExpired(payload: JwtPayload): boolean {
    if (environment.showConsoleLogs) {
      console.log(
        `Seconds Left in Bearer Token Session: ${+(payload?.exp?.toFixed(0) || 0) - +(Date.now() / 1000).toFixed(0)}`
      );
    }

    if (payload.exp) {
      return +payload?.exp?.toFixed(0) < +(Date.now() / 1000).toFixed(0); // check if token is expired
    }
    return true;
  }

  clean() {
    this.tokens = null;
  }
}
