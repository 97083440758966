// Angular Imports
import { Injectable } from '@angular/core';
// NgRx Imports
import { Actions, createEffect, ofType } from '@ngrx/effects';
// rxjs
import { exhaustMap, forkJoin, map, tap } from 'rxjs';
// Actions
import * as appDataActions from './app-data.actions';
import * as userActions from '../user-store/user.actions';
// Services
import { LookupService } from '../../core/services/lookups.service';
import { ErrorHandlingService } from '../../core/services/error-handling.service';
import { AdminService } from '../../core/services/admin.service';

@Injectable()
export class AppDataStoreEffects {
  constructor(
    private actions$: Actions,
    private lookupService: LookupService,
    private errorService: ErrorHandlingService,
    private adminService: AdminService
  ) {}

  loadDataPostAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.loadUserAccountInfoSucceeded),
      exhaustMap(() => {
        return forkJoin({
          divisions: this.lookupService.getDivisions(),
          employeeRoles: this.lookupService.getEmployeeRoles(),
          departments: this.lookupService.getAllDepartments(),
          dailyQuote: this.adminService.getActiveDailyQuote(),
          globalAlert: this.adminService.getActivelGlobalAlert(),
        }).pipe(map((data) => appDataActions.loadAppDataCompleted({ appData: data })));
      })
    );
  });

  // showAlertOnFailure
  showAlertOnFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(appDataActions.loadAppDataFailed),
        tap(({ error }) => this.errorService.onGeneralError(error))
      );
    },
    { dispatch: false }
  );
}
