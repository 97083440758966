<rts-main-layout></rts-main-layout>
<div
  id="overlay"
  [ngClass]="{
    'fade-in-initial': (isLoading$ | async) === true,
    'fade-out': (isLoading$ | async) === false
  }">
  <div class="spinner"></div>
  <br />
  <span id="overlay-text">Getting everything ready...</span>
</div>
