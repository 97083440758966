// Angular Imports
import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Location } from '@angular/common';
// rxjs
import { BehaviorSubject } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RouterEventsService {
  // save the previous route
  public previousRoutePath = new BehaviorSubject<string>('');
  private previousUrl: string | undefined = undefined;
  private currentUrl: string | undefined = undefined;

  constructor(private router: Router, private location: Location) {
    // ..initial previous route will be the current path for now
    this.previousRoutePath.next(this.location.path());
    // on every route change take the two events of two routes changed(using pairwise)
    this.router.events
      .pipe(
        filter((e) => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((event: any[]) => {
        this.previousRoutePath.next(event[0].urlAfterRedirects);
        this.previousUrl = event[0].urlAfterRedirects;
        this.currentUrl = event[1].urlAfterRedirects;
      });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public getCurrentUrl() {
    return this.currentUrl;
  }
}
