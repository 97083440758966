// NgRx Imports
import { createSelector, MemoizedSelector } from '@ngrx/store';
// Store Selectors
import { UserStoreSelectors } from './user-store';
import { DisplayStoreSelectors } from './display-store';
import { TokenStoreSelectors } from './token-store';
import { AppDataStoreSelectors } from './app-data-store';
// Interfaces/Models
import { UserProfileDto } from '../core/interfaces/user/user-profile-dto';

export const selectError = createSelector(UserStoreSelectors.selectUserError, (userError: string) => {
  return userError;
});

export const selectSideNavOpen = createSelector(DisplayStoreSelectors.selectSideNavState, (sideNavState: boolean) => {
  return sideNavState;
});

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
  UserStoreSelectors.selectUserIsLoading,
  TokenStoreSelectors.selectTokenIsLoading,
  DisplayStoreSelectors.selectDisplayIsLoading,
  AppDataStoreSelectors.selectAppDataIsLoading,
  (userIsLoading: boolean, tokenIsLoading: boolean, displayIsLoading, appDataIsLoading: boolean) => {
    return userIsLoading || tokenIsLoading || displayIsLoading || appDataIsLoading;
  }
);

export const selectIsUserAuthenticated = createSelector(
  UserStoreSelectors.selectUserIsLoggedIn,
  (userAuthStatus: boolean) => {
    return userAuthStatus;
  }
);

export const selectUser = createSelector(UserStoreSelectors.selectUser, (user: UserProfileDto | null) => {
  return user;
});
