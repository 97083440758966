// Angular Imports
import { Injectable } from '@angular/core';

// NgRx
import { Store } from '@ngrx/store';
// Store
import * as appDataStoreActions from '../../root-store/app-data-store/app-data.actions';
// rxjs
import { forkJoin } from 'rxjs';
// Services
import { LookupService } from '../../core/services/lookups.service';

@Injectable({
  providedIn: 'root',
})
export class RegisterResolver {
  constructor(private lookupService: LookupService) {}

  resolve() {
    return forkJoin([this.lookupService.getDivisions(), this.lookupService.getEmployeeRoles()]);
  }
}
