// NgRx
import { Action, createReducer, on } from '@ngrx/store';
// Actions
import * as appDataActions from './app-data.actions';
// State
import * as appDataState from './app-data.state';

const appDataReducer = createReducer(
  appDataState.initialState,
  on(
    appDataActions.loadAppDataRequested,
    (state): appDataState.State => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    appDataActions.loadAppDataCompleted,
    (state, { appData }): appDataState.State => ({
      ...state,
      appData,
      isLoading: false,
    })
  )
);

export function reducer(state: appDataState.State | undefined, action: Action) {
  return appDataReducer(state, action);
}
