// Angular Imports
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, FormControl } from '@angular/forms';

// Angular Material
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
// rxjs
import {
  Subject,
  takeUntil,
  Observable,
  forkJoin,
  OperatorFunction,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  of,
} from 'rxjs';
// Services
import { FormUtilityService } from '../../../core/services/form-utility.service';
import { AdminService } from '../../../core/services/admin.service';
import { ErrorHandlingService } from '../../../core/services/error-handling.service';
// Angular Material
import { NgbModal, NgbModalConfig, NgbTypeaheadModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

// Ag-Grid
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

import { QuoteDto } from '../../../core/interfaces/admin/quote-dto';

@Component({
  selector: 'rts-quote-search-row-cell-renderer',
  standalone: true,
  imports: [CommonModule,
    NgbTypeaheadModule,
    FormsModule,
    MatAutocompleteModule
    ],
  templateUrl: './quote-search-row-cell-renderer.component.html',
  styleUrls: ['./quote-search-row-cell-renderer.component.scss'],
})
export class QuoteSearchRowCellRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  allQuotesList: QuoteDto[] | null = null;
  quoteModelComplete: QuoteDto | null = null;
  quoteModel = '';
  searching = false;
  constructor(
    private adminService: AdminService,
    private errorService: ErrorHandlingService
  ) {
  }
  // Mandatory - Params for rendering
  agInit(params: ICellRendererParams): void {
    //console.log(params);
    this.params = params;
    this.quoteModel = params.value;

    this.adminService.getQuotes().subscribe(
      (quotes) => {
        this.allQuotesList = quotes;
      },
      (error) => {
        console.error('Error fetching quotes', error);
        // Handle the error as needed
      }
    );
  }
  refreshCell() {
    // Refresh the current cell
    this.params.api.refreshCells({ rowNodes: [this.params.node], force: true });
  }
  // Fetch quotes when the grid is initialized
  afterGuiAttached?(params?: ICellRendererParams): void {
    this.adminService.getAllDailyQuotes().subscribe(() => {
      this.params.api.refreshCells(); // Refresh all cells once quotes are available
    });
  }

  selectQuoteItem(event: any) {
    if (this.allQuotesList) {
      this.quoteModelComplete = this.allQuotesList.find((quote) => quote.quoteText === this.quoteModel) || null;
      this.params.data.alertQuoteId = this.quoteModelComplete?.alertQuoteId;
    } else {
      // Handle the case where allQuotesList is null
      this.quoteModelComplete = null;
    }

  }
  onInputChange(value: string|null) {
    //if (!this.options.includes(value)) {
      this.quoteModel = '';
    //}
  }
  searchDailyQuotes: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => (this.searching = true)),
    switchMap((term) => {
      if (this.allQuotesList && term.length > 1) { // Null check added here
        return of(
          this.allQuotesList
            .map((quote) => quote.quoteText)
            .filter((quoteText) => quoteText.toLowerCase().includes(term.toLowerCase()))
        );
      } else {
        return of([]); // Return an empty array if this.allQuotesList is null or term is too short
      }
    }),
    tap(() => (this.searching = false))
  );
  // Mandatory - Return true if you have managed the cell refresh yourself in this method, otherwise return false.
  // If you return false, the grid will remove the component from the DOM and create a new component in its place
  // with the new values.
  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
