<span class="r2s-global-alert" matSnackBarLabel>
  {{ (alert | async)?.alertText }}
</span>
<span matSnackBarActions>
  <a
    *ngIf="(alert | async)?.buttonText"
    class="r2s-alert-action-btn"
    [href]="(alert | async)?.buttonUrl"
    target="_blank"
    mat-stroked-button
    matSnackBarAction
    (click)="snackBarRef.dismissWithAction()"
    >{{ (alert | async)?.buttonText }}</a
  >
  <button
    type="button"
    role="button"
    class="r2s-alert-close-btn"
    mat-icon-button
    matSnackBarAction
    (click)="snackBarRef.dismissWithAction()">
    <mat-icon>close</mat-icon>
  </button>
</span>
