<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="isSideNavOpen$ | async"
    (closedStart)="dispatchCloseSidenav()">
    <mat-toolbar>
      <img class="img-fluid" ngSrc="assets/navlogo.webp" priority height="25.49" width="253" />
    </mat-toolbar>
    <rts-side-nav-list [canAccessForecasting]="canAccessForecasting" [userValue]="userValue"></rts-side-nav-list>
  </mat-sidenav>
  <mat-sidenav-content role="main" class="main">
    <nav
      *ngIf="(this.isLoggedIn$ | async) === true && (isHandset$ | async) === false"
      class="navbar navbar-expand-lg post-login">
      <div class="container-fluid">
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleSideNav()">
          <mat-icon aria-label="Toggle Side Navigation">menu</mat-icon>
        </button>
        <ngb-alert type="secondary" [dismissible]="false">
          <mat-icon class="top-alert-icon-display me-3" color="primary"
            ><span class="material-icons">format_quote</span></mat-icon
          >
          {{ (dailyQuote | async)?.quoteText }}
        </ngb-alert>
        <div class="navbar-nav r2s-user-nav">
          <div class="r2s-welcome-user">Welcome, {{ (user$ | async)?.firstName }}!</div>
          <a mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="r2s-user-icon">account_circle</mat-icon></a>
          <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="/profile">Profile</button>
            <button mat-menu-item routerLink="/admin" *ngIf="canAccessAdmin">Admin</button>
            <button mat-menu-item routerLink="/register" *ngIf="canAccessRegister">Register a New User</button>
          </mat-menu>
          <a mat-icon-button (click)="logOut()"><mat-icon class="r2s-user-icon">logout</mat-icon></a>
        </div>
      </div>
    </nav>
    <!-- Router-Outlet for Page Content -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<div class="mobile-container" *ngIf="(isHandset$ | async) === true">
  <a class="mobile-nav-item" routerLink="/dashboard" routerLinkActive="active">
    <mat-icon class="mobile-icon">dashboard</mat-icon>
    <span>Dashboard</span>
  </a>
  <a class="mobile-nav-item" routerLink="/goals" routerLinkActive="active">
    <mat-icon class="mobile-icon">flag</mat-icon>
    <span>Goals</span>
  </a>
  <a *ngIf="canAccessForecasting" class="mobile-nav-item" routerLink="/desk-log" routerLinkActive="active">
    <mat-icon class="mobile-icon">description</mat-icon>
    <span>Desk Log</span>
  </a>
  <a class="mobile-nav-item" routerLink="/forecasting" routerLinkActive="active" *ngIf="canAccessForecasting">
    <mat-icon class="mobile-icon">storm</mat-icon>
    <span>Forecasting</span>
  </a>
  <a class="mobile-nav-item" routerLink="/work-days" routerLinkActive="active" *ngIf="canAccessForecasting">
    <mat-icon class="mobile-icon">event</mat-icon>
    <span>Working Days</span>
  </a>
  <a class="mobile-nav-item" routerLink="/desk-log-summary" routerLinkActive="active" *ngIf="canAccessForecasting">
    <mat-icon class="mobile-icon">summarize</mat-icon>
    <span>DLS</span>
  </a>
  <!-- <a class="mobile-nav-item" routerLink="/profile" routerLinkActive="active">
    <mat-icon class="mobile-icon">account_circle</mat-icon>
    <span>Profile</span>
  </a> -->
  <a class="mobile-nav-item" [matMenuTriggerFor]="menu">
    <mat-icon class="mobile-icon">account_circle</mat-icon><span>Profile</span></a
  >
  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/profile">Profile</button>
    <button mat-menu-item routerLink="/admin" *ngIf="canAccessAdmin">Admin</button>
    <button mat-menu-item routerLink="/register" *ngIf="canAccessRegister">Register a New User</button>
    <button mat-menu-item routerLink="/goals/user" *ngIf="canAccessUserData">Manage User Goals</button>
  </mat-menu>
</div>
