<div class="button-container">
  <span (click)="onDeleteClick()" style="padding-right: 10px;">
    <button type="button" mat-icon-button matListItemIcon  class="custom-button">
      <mat-icon class="action-icon-display" aria-hidden="true">delete</mat-icon>
    </button>
  </span>
  <span (click)="onSaveClick()" style="padding-left: 10px;">
    <button type="button" mat-icon-button matListItemIcon class="custom-button">
      <mat-icon class="action-icon-display" aria-hidden="true">save</mat-icon>
    </button>
  </span>
</div>
