// Angular Imports
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
// rxjs
import { map, Observable, Subject, takeUntil, tap } from 'rxjs';
// Angular Material
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
// Ng-Bootstrap
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
// Store
import { Store } from '@ngrx/store';
import { UserStoreSelectors, DisplayStoreSelectors, AppDataStoreSelectors } from '../../../root-store';
// Actions
import * as TokenActions from '../../../root-store/token-store/token.actions';
import * as DisplayStoreActions from '../../../root-store/display-store/display.actions';
// Interfaces/Models
import { UserProfileDto } from '../../interfaces/user/user-profile-dto';
// Services
import { UserPermissionsService } from '../../services/user-permissions.service';
// Child Components
import { SideNavListComponent } from '../side-nav-list/side-nav-list.component';
import { GlobalAlertComponent } from '../../../shared/components/alerts/global-alert/global-alert.component';
// environment
import { environment } from './../../../../environments/environment';
import { QuoteDto } from '../../interfaces/admin/quote-dto';
import { AlertDto } from '../../interfaces/admin/alert-dto';

@Component({
  selector: 'rts-main-layout',
  standalone: true,
  imports: [
    CommonModule,
    LayoutModule,
    NgOptimizedImage,
    RouterModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatRippleModule,
    MatBadgeModule,
    MatMenuModule,
    MatSnackBarModule,
    NgbAlertModule,
    NgbCollapseModule,
    NgbToastModule,
    SideNavListComponent,
    GlobalAlertComponent,
  ],
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  _snackBarRef: MatSnackBarRef<GlobalAlertComponent> | null = null;
  canAccessRegister = false;
  canAccessAdmin = false;
  canAccessForecasting = false;
  dailyQuote: Observable<QuoteDto | null | undefined>;
  globalAlert: Observable<AlertDto | null | undefined> | undefined = undefined;
  // Applies class to parent angular div for proper flex display
  @HostBinding('className') mainWrapper = 'main-wrapper';
  showAlert = true;
  user$: Observable<UserProfileDto | null>;
  userValue: UserProfileDto | null = null;
  isUserLoaded$: Observable<boolean>;
  isUserLoadedValue = false;
  isLoggedIn$: Observable<boolean>;
  isSideNavOpen$: Observable<boolean>;
  canAccessUserData = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 900px)']).pipe(
    map((result) => result.matches),
    tap((match) => {
      if (match) {
        this.store.dispatch(DisplayStoreActions.forceSideNavClose());
      }
    })
    //shareReplay() // uncomment this line if you do not need the isHandset variable to update as the screen size changes vs. just initial device load.
  );

  isMenuCollapsed = true;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private store: Store,
    private _snackBar: MatSnackBar,
    private userPermissionsService: UserPermissionsService
  ) {
    this.dailyQuote = this.store.select(AppDataStoreSelectors.selectAppDataDailyQuote);
    this.globalAlert = this.store.select(AppDataStoreSelectors.selectAppDataGlobalAlert);
    this.user$ = this.store.select(UserStoreSelectors.selectUser);
    this.isUserLoaded$ = this.store.select(UserStoreSelectors.selectUserIsFullLoaded);
    this.isUserLoaded$.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      this.isUserLoadedValue = result;
      if (this.isUserLoadedValue) {
        this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
          if (result?.primaryRoleId && result?.primaryRoleId?.length > 0) {
            if (this.userPermissionsService.isAuthAdmin()) {
              this.canAccessAdmin = true;
              this.canAccessForecasting = true;
              this.canAccessRegister = true;
            } else if (this.userPermissionsService.isAuthManager()) {
              this.canAccessAdmin = false;
              this.canAccessForecasting = true;
              this.canAccessRegister = true;
            }

            if (
              this.userPermissionsService.isUserManager() ||
              this.userPermissionsService.isR2sAdmin() ||
              this.userPermissionsService.isSiteAdmin()
            ) {
              this.canAccessUserData = true;
            }

            // this._snackBarRef = this._snackBar.openFromComponent(GlobalAlertComponent, {
            //   data: this.globalAlert,
            // });

            this.userValue = result;
          } else if (this._snackBarRef) {
            this._snackBarRef.dismiss();
          }
        });
      } else {
        this.canAccessAdmin = false;
        this.canAccessForecasting = false;
        this.canAccessRegister = false;
      }
    });

    this.isLoggedIn$ = this.store.select(UserStoreSelectors.selectUserIsLoggedIn);
    this.isSideNavOpen$ = this.store.select(DisplayStoreSelectors.selectSideNavState);
  }

  ngOnInit(): void {
    if (environment.showConsoleLogs) {
      console.log('Main Layout Component Init Success');
    }

    this.handleSnackBar();
  }

  handleSnackBar() {
    this.globalAlert?.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      if (result?.alertText) {
        this._snackBarRef = this._snackBar.openFromComponent(GlobalAlertComponent, {
          data: this.globalAlert,
        });
      }
    });
  }

  toggleSideNav() {
    this.store.dispatch(DisplayStoreActions.toggleSideNav());
  }

  dispatchCloseSidenav() {
    this.isHandset$.pipe(
      tap((result) => {
        if (result) {
          this.store.dispatch(DisplayStoreActions.forceSideNavClose());
        }
      })
    );
  }

  logOut() {
    this.store.dispatch(TokenActions.forceLogOut());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // dispatchOpenSidenav() {
  //   this.store.dispatch(DisplayStoreActions.forceSideNavOpen());
  // }
}
