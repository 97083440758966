// Angular Imports
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Angular Material
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion'; // Add this import

import { UserProfileDto } from '../../interfaces/user/user-profile-dto';

@Component({
  selector: 'rts-side-nav-list',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatExpansionModule, // Add this module
  ],
  templateUrl: './side-nav-list.component.html',
  styleUrls: ['./side-nav-list.component.scss'],
})
export class SideNavListComponent {
  @Input() userValue: UserProfileDto | null = null;
  @Input() canAccessForecasting = false;
}
