// Angular Imports
import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
// NgRx
import { Store } from '@ngrx/store';
// rxjs
import { Subject, Observable } from 'rxjs';
// Environment
import { environment } from '../environments/environment';
// Store
import { RootStoreSelectors } from './root-store';
import { NgClass, AsyncPipe } from '@angular/common';
import { MainLayoutComponent } from './core/layout/main-layout/main-layout.component';

@Component({
  selector: 'rts-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [MainLayoutComponent, NgClass, AsyncPipe],
})
export class AppComponent implements OnInit, OnDestroy {
  // Used to enforce flex layout app-wide
  @HostBinding('className') wrapper = 'main-wrapper';
  isLoading$: Observable<boolean> | undefined;

  private unsubscribe$ = new Subject<void>();

  isAuthenticated = false;

  constructor(private store: Store) {}

  ngOnInit() {
    if (environment.showConsoleLogs) {
      console.log('RTS - App Component initialized');
    }

    this.isLoading$ = this.store.select(RootStoreSelectors.selectIsLoading);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
