// Angular Imports
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// NgRx Imports
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
// rxjs
import { of, catchError, map, exhaustMap, tap } from 'rxjs';
// Services
import { UserService } from '../../core/services/user.service';
import { RouterEventsService } from '../../core/services/router-event.service';
import { ErrorHandlingService } from '../../core/services/error-handling.service';
// Actions
import * as UserActions from './user.actions';
import * as TokenActions from '../token-store/token.actions';
import * as DisplayActions from '../display-store/display.actions';
// Selectors
import { AppDataStoreActions, TokenStoreSelectors } from '../../root-store';
// NgRx
import * as RouterStoreSelectors from '../router-store/router.selectors';

@Injectable()
export class UserStoreEffects {
  constructor(
    private userService: UserService,
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private routerEventsService: RouterEventsService,
    private errorService: ErrorHandlingService
  ) {}

  loadTokenBeginUserLoadingIndicator$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.startUserLoad),
      map((props) => {
        return TokenActions.loadTokenRequested({ userLogin: props.userLogin });
      }),
      catchError((error) => of(UserActions.loadUserFailed({ error: error.message })))
    );
  });

  updateUserInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TokenActions.tokenDecodedSuccess),
      map((props) => {
        const claims = props.decodedTokenObj;
        //console.log(claims);
        const userAccount = this.userService.createInitialUserObj(claims);
        return UserActions.loadUserSucceeded({ userAccount: userAccount });
      }),
      catchError((error) => of(UserActions.loadUserFailed({ error: error.message })))
    );
  });

  updateUserAccountInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUserSucceeded),
      exhaustMap((props) => {
        //console.log(props.userAccount);
        return this.userService.getUserAccountInfo(props.userAccount.id).pipe(
          map((userAccountResponse) => {
            if (userAccountResponse) {
              userAccountResponse.primaryRoleId = props.userAccount.primaryRoleId;
              //console.log(userAccountResponse);
              return UserActions.loadUserAccountInfoSucceeded({
                userInfo: userAccountResponse,
              });
            }
            return UserActions.loadUserFailed({
              error: 'No Valid Response From /Account API',
            });
          }),
          catchError((error) => of(UserActions.loadUserFailed({ error: error.message })))
        );
      })
    );
  });

  redirectUserPostAuthFlow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppDataStoreActions.loadAppDataCompleted),
      concatLatestFrom(() => this.store.select(TokenStoreSelectors.selectLoginAttemptFromUI)),
      concatLatestFrom(() => this.store.select(TokenStoreSelectors.selectLoginAttemptFromRefresh)),
      concatLatestFrom(() => this.store.select(RouterStoreSelectors.selectUrl1)),
      map((props) => {
        const loginAttemptFromUI = props[0][0][1];
        //console.log(`Is Login Attempt From UI LOGIN page?: ${loginAttemptFromUI}`);
        const isFromRefreshFlow = props[0][1];
        //console.log(`Is Auth Attempt from REFRESH TOKEN FLOW?: ${isFromRefreshFlow}`);
        const routerUrl = props[1].state.url;
        //console.log(`Current URL: ${routerUrl}`);
        const previousUrl = this.routerEventsService.getPreviousUrl();
        //console.log(`Previous URL: ${previousUrl}`);

        if (isFromRefreshFlow && previousUrl) {
          this.router.navigate([this.routerEventsService.getPreviousUrl()]);
        } else if ((loginAttemptFromUI && !previousUrl) || routerUrl?.includes('login')) {
          this.router.navigate(['/dashboard']);
        }
        return UserActions.postAuthRedirectSuccess();
      })
    );
  });

  loadUserShowSideNav$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppDataStoreActions.loadAppDataCompleted),
      map(() => {
        return DisplayActions.forceSideNavOpen();
      })
    );
  });

  forceLogOutUserData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.forceLogOutClearUser),
      map((props) => {
        this.router.navigate(['/login']);
        return UserActions.logOutSuccess();
      }),
      catchError((error) => of(TokenActions.loadTokenFailed({ error: error.message })))
    );
  });

  // showAlertOnFailure
  showAlertOnFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.loadUserFailed),
        //tap(({ error }) => this.errorService.onGeneralError(error)),
        map((props) => {
          this.router.navigate(['/login']);
          return UserActions.forceLogOutClearUser();
        })
      );
    },
    { dispatch: false }
  );
}
