// NgRx
import { Action, createReducer, on } from '@ngrx/store';
// Token Actions
import * as tokenActions from './token.actions';
import * as userActions from '../user-store/user.actions';
// Token State
import * as tokenState from './token.state';

const tokenReducer = createReducer(
  tokenState.initialState,
  on(
    tokenActions.loadTokenRequested,
    (state): tokenState.State => ({
      ...state,
      isLoading: true,
      error: '',
      decoded: false,
    })
  ),
  on(
    tokenActions.loadTokenFromUIRequested,
    (state): tokenState.State => ({
      ...state,
      isLoading: true,
      error: '',
      decoded: false,
      loginFromUI: true,
    })
  ),
  on(
    tokenActions.loadTokenSucceeded,
    (state): tokenState.State => ({
      ...state,
      isLoading: true,
      decoded: false,
    })
  ),
  on(
    tokenActions.tokenDecodedSuccess,
    (state, { tokenDetails }): tokenState.State => ({
      ...state,
      tokenDetails,
      isLoading: false,
      decoded: true,
    })
  ),
  on(
    tokenActions.refreshFlowRequested,
    (state, { tokenDetails }): tokenState.State => ({
      ...state,
      tokenDetails,
      isLoading: true,
      decoded: false,
      isFromRefreshFlow: true,
    })
  ),
  on(
    tokenActions.refreshFlowSucceeded,
    (state): tokenState.State => ({
      ...state,
      isLoading: false,
      decoded: false,
      isFromRefreshFlow: true,
      loginFromUI: true,
    })
  ),
  on(
    tokenActions.loadTokenFailed,
    (state, { error }): tokenState.State => ({
      ...state,
      error,
      isLoading: true,
      decoded: false,
    })
  ),
  on(
    tokenActions.forceLogOut,
    (state): tokenState.State => ({
      ...state,
      tokenDetails: null,
      decoded: false,
      isLoading: true,
      loginFromUI: false,
      isFromRefreshFlow: false,
      error: '',
    })
  ),
  on(
    userActions.forceLogOutClearUser,
    (state): tokenState.State => ({
      ...state,
      tokenDetails: null,
      decoded: false,
      isLoading: false,
      loginFromUI: false,
      isFromRefreshFlow: false,
      error: '',
    })
  )
);

export function reducer(state: tokenState.State | undefined, action: Action) {
  return tokenReducer(state, action);
}
