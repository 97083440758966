<div class="col-sm-12">
  <select
    [(ngModel)]="quoteModel"
    id="quote-select"
    class="form-select form-select-sm"
    (change)="selectQuoteItem($event)">
    >
    <option value="" disabled selected>Select a quote</option>
    <!-- Add ngFor to loop through your quote options -->
    <option *ngFor="let quote of allQuotesList" [value]="quote.quoteText">
      {{ quote.quoteText }}
    </option>
  </select>
</div>
