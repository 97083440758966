// Angular imports
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
// Environment Specific
import { environment } from './../../../environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpHeaderService {
  formToken = '';
  cookieToken = '';
  bearerToken = '';
  tokenExpiryInSeconds = 0;
  private newAccessTokenEvent: Subject<string>;

  constructor() {
    if (environment.showConsoleLogs) {
      console.log('HttpHeader Service Loaded');
    }
    this.newAccessTokenEvent = new Subject<string>();
  }

  public setBearerToken(bearerToken: string) {
    this.bearerToken = bearerToken;
    this.newAccessTokenEvent.next(bearerToken);
  }

  public getBearerHeaders(): HttpHeaders {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('Authorization', 'Bearer ' + this.bearerToken);
    return headers;
  }

  public getNoAuthHeaders(): HttpHeaders {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return headers;
  }

  // Use for post requests that don't accept content-type of json
  public getCustomFormHeaders(): HttpHeaders {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.bearerToken);
    return headers;
  }

  /**
   * Occurs when a new access token is generated.
   */
  onNewAccessToken(): Observable<string> {
    return this.newAccessTokenEvent.asObservable();
  }
}
