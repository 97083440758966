// NgRx Imports
import { Action, createReducer, on } from '@ngrx/store';
// User Actions
import * as userActions from './user.actions';
import * as appDataActions from '../app-data-store/app-data.actions';
// User State
import * as userState from './user.state';

const userReducer = createReducer(
  userState.initialState,
  on(
    userActions.startUserLoad,
    (state): userState.State => ({
      ...state,
      isLoading: true,
      isLoggedIn: false,
      loginRedirectSuccess: false,
    })
  ),
  on(
    userActions.loadUserRequested,
    (state): userState.State => ({
      ...state,
      isLoading: true,
      error: '',
      isLoggedIn: false,
    })
  ),
  on(
    userActions.loadUserSucceeded,
    (state, { userAccount }): userState.State => ({
      ...state,
      userAccount,
      isLoading: true,
      isLoggedIn: false,
      loginRedirectSuccess: false,
    })
  ),
  on(
    userActions.loadUserAccountInfoSucceeded,
    (state, { userInfo }): userState.State => ({
      ...state,
      userInfo,
      isLoading: true,
      isLoggedIn: false,
      isCompleteUserInfoLoaded: true,
      loginRedirectSuccess: false,
    })
  ),
  on(
    userActions.postAuthRedirectSuccess,
    (state): userState.State => ({
      ...state,
      isLoading: false,
      isLoggedIn: true,
      loginRedirectSuccess: true,
    })
  ),
  on(
    userActions.forceUserLoadingFlag,
    (state): userState.State => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    userActions.loadUserFailed,
    (state, { error }): userState.State => ({
      ...state,
      error,
      isLoading: false,
      isLoggedIn: false,
      isCompleteUserInfoLoaded: false,
      loginRedirectSuccess: false,
    })
  ),
  on(
    userActions.forceLogOutClearUser,
    (state): userState.State => ({
      ...state,
      userInfo: null,
      isLoading: true,
      error: '',
      isLoggedIn: false,
      isCompleteUserInfoLoaded: false,
      loginRedirectSuccess: false,
    })
  ),
  on(
    userActions.logOutSuccess,
    (state): userState.State => ({
      ...state,
      userInfo: null,
      isLoading: false,
      error: '',
      isLoggedIn: false,
      isCompleteUserInfoLoaded: false,
      loginRedirectSuccess: false,
    })
  )
);

export function reducer(state: userState.State | undefined, action: Action) {
  return userReducer(state, action);
}
