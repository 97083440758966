// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// NgRx
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
// Reducer
import * as fromAppData from './app-data.reducer';
// State
import * as appDataState from './app-data.state';
// Effects
import { AppDataStoreEffects } from './app-data.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(appDataState.appDataFeatureKey, fromAppData.reducer),
    EffectsModule.forFeature([AppDataStoreEffects]),
  ],
})
export class AppDataStoreModule {}
