// NgRx
import { createFeatureSelector, createSelector } from '@ngrx/store';
// User State
import * as userState from './user.state';

const selectGetUserState = createFeatureSelector<userState.State>(userState.userFeatureKey);

export const selectUser = createSelector(selectGetUserState, (state) => state.userInfo);

export const selectUserIsLoggedIn = createSelector(selectGetUserState, (state) => state.isLoggedIn);

export const selectUserError = createSelector(selectGetUserState, (state) => state.error);

export const selectUserIsLoading = createSelector(selectGetUserState, (state) => state.isLoading);

export const selectUserIsFullLoaded = createSelector(selectGetUserState, (state) => state.isCompleteUserInfoLoaded);
