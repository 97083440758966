// Angular Imports
import { Injectable } from '@angular/core';
// NgRx Imports
import { Actions, createEffect, ofType } from '@ngrx/effects';
// rxjs
import { of, catchError, map, exhaustMap, tap } from 'rxjs';
// Services
import { LoginService } from '../../core/services/login.service';
import { StorageService } from '../../core/services/storage.service';
import { JwtTokenService } from '../../core/services/jwt-token.service';
import { ErrorHandlingService } from '../../core/services/error-handling.service';
// Actions
import * as TokenActions from './token.actions';
import * as UserActions from '../user-store/user.actions';
// Interfaces/Models
import { TokenDetails } from '../../core/interfaces/store/token-details';

@Injectable()
export class TokenStoreEffects {
  constructor(
    private loginService: LoginService,
    private actions$: Actions,
    private storageService: StorageService,
    private tokenService: JwtTokenService,
    private errorService: ErrorHandlingService
  ) {}

  loadTokenFromUI$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TokenActions.loadTokenFromUIRequested),
      map((props) => {
        return UserActions.startUserLoad({ userLogin: props.userLogin });
      })
    );
  });

  loadToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TokenActions.loadTokenRequested),
      exhaustMap((props) =>
        this.loginService.login(props.userLogin).pipe(
          map((tokenResponse) => {
            return TokenActions.loadTokenSucceeded({
              tokenResponse: tokenResponse,
            });
          }),
          catchError((error) => of(TokenActions.loadTokenFailed({ error: error.message })))
        )
      )
    );
  });

  saveToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TokenActions.loadTokenSucceeded),
      map((props) => {
        const tokenDecoded = this.tokenService.decodeToken(props.tokenResponse.auth.bearer);
        const tokenInformation: TokenDetails = {
          bearer: props.tokenResponse.auth.bearer,
          refresh: props.tokenResponse.auth.refresh,
          exp: tokenDecoded.exp,
        };
        this.storageService.saveToken(tokenInformation);
        return TokenActions.tokenDecodedSuccess({
          tokenDetails: tokenInformation,
          decodedTokenObj: tokenDecoded,
        });
      }),
      catchError((error) => of(TokenActions.loadTokenFailed({ error: error.message })))
    );
  });

  validateRefreshToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TokenActions.refreshFlowRequested),
      exhaustMap((props) =>
        this.tokenService.callRefreshTokenApi(props.tokenDetails).pipe(
          map((tokenRefreshResponse) => {
            return TokenActions.loadTokenSucceeded({
              tokenResponse: tokenRefreshResponse,
            });
          }),
          catchError((error) => of(TokenActions.loadTokenFailed({ error: error.message })))
        )
      ),
      catchError((error) => of(TokenActions.loadTokenFailed({ error: error.message })))
    );
  });

  forceLogOutClearData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TokenActions.forceLogOut),
      map((props) => {
        this.storageService.clean();
        this.tokenService.clean();
        return UserActions.forceLogOutClearUser();
      }),
      catchError((error) => of(TokenActions.loadTokenFailed({ error: error.message })))
    );
  });

  // showAlertOnFailure
  showAlertOnFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TokenActions.loadTokenFailed),
      //tap(({ error }) => this.errorService.onGeneralError(error)),
      map((props) => {
        return TokenActions.forceLogOut();
      })
    );
  });
}
