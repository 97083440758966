// NgRx
import { Action, createReducer, on } from '@ngrx/store';
// Actions
import * as displayActions from './display.actions';
// State
import * as displayState from './display.state';

const displayReducer = createReducer(
  displayState.initialState,
  on(
    displayActions.toggleSideNav,
    (state): displayState.State => ({
      ...state,
      display: { sideNavVisible: !state.display.sideNavVisible },
      error: '',
    })
  ),
  on(
    displayActions.forceSideNavOpen,
    (state): displayState.State => ({
      ...state,
      display: { sideNavVisible: true },
      error: '',
    })
  ),
  on(
    displayActions.forceSideNavClose,
    displayActions.forceSideNavCloseUserLogOut,
    (state): displayState.State => ({
      ...state,
      display: { sideNavVisible: false },
      error: '',
    })
  ),
  on(
    displayActions.forceShowLoadingIndicator,
    (state): displayState.State => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    displayActions.hideDisplayLoadingIndicator,
    (state): displayState.State => ({
      ...state,
      isLoading: false,
    })
  )
);

export function reducer(state: displayState.State | undefined, action: Action) {
  return displayReducer(state, action);
}
