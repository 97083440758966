<div class="modal-header">
  <h4 class="modal-title">{{ headerText }}</h4>
</div>
<div class="modal-body">
  <p *ngFor="let entry of bodyTextEntries">{{ entry }}</p>
</div>
<div class="modal-footer">
  <button mat-raised-button color="accent" type="button" role="button" (click)="closeClick()">
    {{ confirmBtnTxt }}
  </button>
</div>
