// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

// NgRx imports
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// Custom Store Modules
import { UserStoreModule } from './user-store/user-store.module';
import { DisplayStoreModule } from './display-store';
import { TokenStoreModule } from './token-store';
import { AppDataStoreModule } from './app-data-store';
// environment
import { environment } from '../../environments/environment';
// Custom Route Info Serializer
import { CustomSerializer } from './custom-route-serializer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UserStoreModule,
    DisplayStoreModule,
    MatDialogModule,
    TokenStoreModule,
    AppDataStoreModule,
    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: false,
          autoPause: false,
          name: 'Race2Success NgRx DevTools',
          trace: false,
          //traceLimit: 35,
        })
      : [],
    // Connects RouterModule with StoreModule, uses MinimalRouterStateSerializer by default
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
  ],
})
export class RootStoreModule {}
