// NgRx Imports
import { createFeatureSelector, createSelector } from '@ngrx/store';
// State
import * as displayState from './display.state';

const selectGetdisplayState = createFeatureSelector<displayState.State>(displayState.displayFeatureKey);

export const selectSideNavState = createSelector(selectGetdisplayState, (state) => state.display.sideNavVisible);

export const selectDisplayError = createSelector(selectGetdisplayState, (state) => state.error);

export const selectDisplayIsLoading = createSelector(selectGetdisplayState, (state) => state.isLoading);
