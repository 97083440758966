// Angular Imports
import { Component, Inject, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
// Angular Material
import { MatSnackBarModule, MatSnackBar, MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
// Interfaces
import { AlertDto } from '../../../../core/interfaces/admin/alert-dto';
import { Observable } from 'rxjs';

@Component({
  selector: 'rts-global-alert',
  standalone: true,
  imports: [CommonModule, MatSnackBarModule, MatButtonModule, MatIconModule],
  templateUrl: './global-alert.component.html',
  styleUrls: ['./global-alert.component.scss'],
})
export class GlobalAlertComponent {
  @Input() test = '';
  snackBarRef = inject(MatSnackBarRef);
  alert: Observable<AlertDto | null | undefined>;

  constructor(
    private _snackBar: MatSnackBar,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: Observable<AlertDto | null | undefined>
  ) {
    this.alert = data;
    //console.log(this.alert);
  }
}
