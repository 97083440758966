import { ApplicationConfig } from '@angular/core';
import { enableProdMode, importProvidersFrom, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { RootStoreModule } from './root-store/root-store.module';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { httpInterceptorProviders } from '../app/core/interceptors/http.interceptor';
import { ErrorIntercept } from '../app/core/interceptors/error.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule, RootStoreModule, AppRoutingModule),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true,
      deps: [],
    },
    // httpInterceptorProviders,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
};
