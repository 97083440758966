// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// NgRx
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
// Reducer
import * as fromToken from './token.reducer';
// State
import * as tokenState from './token.state';
// Effects
import { TokenStoreEffects } from './token.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(tokenState.tokenFeatureKey, fromToken.reducer),
    EffectsModule.forFeature([TokenStoreEffects]),
  ],
})
export class TokenStoreModule {}
