// NgRx
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Token State
import * as tokenState from './token.state';

const selectTokenState = createFeatureSelector<tokenState.State>(tokenState.tokenFeatureKey);

export const selectToken = createSelector(selectTokenState, (state) => state.tokenDetails);

export const selectTokenError = createSelector(selectTokenState, (state) => state.error);

export const selectTokenIsLoading = createSelector(selectTokenState, (state) => state.isLoading);

export const selectLoginAttemptFromUI = createSelector(selectTokenState, (state) => state.loginFromUI);

export const selectLoginAttemptFromRefresh = createSelector(selectTokenState, (state) => state.isFromRefreshFlow);
