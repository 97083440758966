export interface User {
  id: string;
  userName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  divisionId: string;
  reportsToId: string;
  phoneNumber: string;
  isActive: boolean;
  role?: string;
  primaryRoleId?:
    | ['site_admin' | 'r2s_admin' | 'user_manager' | 'r2s_user' | 'task_manager' | 'task_job_admin']
    | []
    | null;
}

export const primaryRoleIdConfig: string[] = [
  'site_admin',
  'r2s_admin',
  'user_manager',
  'r2s_user',
  'task_manager',
  'task_job_admin',
];
