// Interfaces/Models
import { TokenDetails } from '../../core/interfaces/store/token-details';

export const tokenFeatureKey = 'token';

// Token State Interface
export interface State {
  tokenDetails: TokenDetails | null;
  decoded: boolean;
  isLoading: boolean;
  loginFromUI: boolean;
  isFromRefreshFlow: boolean;
  error: string;
}

// Default Token State
export const initialState: State = {
  tokenDetails: null,
  decoded: false,
  isLoading: false,
  loginFromUI: false,
  isFromRefreshFlow: false,
  error: '',
};
