// Angular Imports
import { HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
// rxjs
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Store
import { Store } from '@ngrx/store';
// Actions
import * as TokenActions from '../../root-store/token-store/token.actions';
import { inject } from '@angular/core';

// TODO: Handle refresh token failed response
export class ErrorIntercept implements HttpInterceptor {
  constructor(private store: Store) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //const store = inject(Store);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // TODO: Find a way to clear this if user hits the login  page! Just find a way to clear this in the event the refresh token is not valid...
        // TODO: Could be an effect?
        //store.dispatch(TokenActions.forceLogOut());
        //this.utils.hideProgressOverlay();
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error Side: Client\nError: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Side: Server\nError Status: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);

        if (error.error === 'REFRESH TOKEN EXPIRED') {
          // TODO: Stuff here so we can clear the login info...FIX THIS THROWING ERROR SAYING DISPATCH IS NOT A FUNCTION....
          //this.store.dispatch(TokenActions.forceLogOut());
        }
        // TODO: Add functionality in store so when error is thrown and handled, it is handled appropriately for UI/UX
        return throwError(() => error);
      })
    );
  }
}
