// Interfaces/Models
import { User } from '../../core/interfaces/store/user';
import { UserProfileDto } from '../../core/interfaces/user/user-profile-dto';

export const userFeatureKey = 'user';

// User State Interface
export interface State {
  userInfo: UserProfileDto | null;
  userAccount: User | null;
  isLoading: boolean;
  error: string;
  isLoggedIn: boolean;
  isCompleteUserInfoLoaded: boolean;
  loginRedirectSuccess: boolean;
}

// Default User State
export const initialState: State = {
  userInfo: null,
  userAccount: null,
  isLoading: false,
  error: '',
  isLoggedIn: false,
  isCompleteUserInfoLoaded: false,
  loginRedirectSuccess: false,
};
