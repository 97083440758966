// Angular Imports
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
// NgRx
import { Store } from '@ngrx/store';
// Store
import * as displayStoreActions from '../../../root-store/display-store/display.actions';
// 3rd party
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'rts-error-modal',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
  @Input() headerText = '';
  @Input() bodyTextEntries: string[] = [];
  @Input() confirmBtnTxt = 'Close';
  @Input() dismisAll = true;

  constructor(public activeModal: NgbActiveModal, private store: Store, private modalService: NgbModal) {}

  ngOnInit() {
    this.store.dispatch(displayStoreActions.hideDisplayLoadingIndicator());
  }

  closeClick() {
    if (this.dismisAll) {
      this.modalService.dismissAll();
    } else {
      this.activeModal.close();
    }
  }
}
