// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// NgRx
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
// Reducer
import * as fromUser from './user.reducer';
// State
import * as userState from './user.state';
// Effects
import { UserStoreEffects } from './user.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(userState.userFeatureKey, fromUser.reducer),
    EffectsModule.forFeature([UserStoreEffects]),
  ],
})
export class UserStoreModule {}
