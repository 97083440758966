// NgRx
import { createAction, props } from '@ngrx/store';

export const toggleSideNav = createAction('[Display] Toggle Side Nav');

export const forceSideNavOpen = createAction('[Display] Force Side Nav Open');

export const forceSideNavClose = createAction('[Display] Force Side Nav Close');

export const forceSideNavCloseUserLogOut = createAction('[Display] Force Side Nav Close - User Log Out');

export const forceShowLoadingIndicator = createAction('[Display] Force Show Loading Indicator');

export const hideDisplayLoadingIndicator = createAction('[Display] Hide Display Loading Indicator');
