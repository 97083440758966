// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// rxjs
import { map, tap } from 'rxjs';
// Services
import { HttpHeaderService } from './http-header.service';
// Environment Specific
import { environment } from './../../../environments/environment';
// Models/Interfaces
import { User } from '../interfaces/store/user';
import { CreateUserDto } from '../interfaces/user/create-user-dto';
import { UserProfileDto } from '../interfaces/user/user-profile-dto';
import { primaryRoleIdConfig } from '../interfaces/store/user';
import { UpdateUserDto } from '../interfaces/user/update-user-dto';

@Injectable({
  providedIn: 'root',
})

/** USER SPECIFIC Data storage and management related to the currently logged in user.
 * Contains API calls related to user data.
 */
export class UserService {
  userAccountInfoUri = environment.baseUri + '/api/Account';
  createUserUri = environment.baseUri + '/api/Account/createuser';
  updateUserUri = environment.baseUri + '/api/Account/Update';

  constructor(private http: HttpClient, private httpHeaderService: HttpHeaderService) {
    if (environment.showConsoleLogs) {
      console.log('User Service Loaded');
    }
  }

  createUser(body: CreateUserDto) {
    return this.http
      .post(this.createUserUri, body, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs ? console.log('called createUser - ' + _.status + ' - ' + _.statusText) : void 0
        )
        //map((response) => response.body)
      );
  }

  updateUser(body: UpdateUserDto, userId: string) {
    return this.http
      .post(this.updateUserUri + '/' + userId, body, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs ? console.log('called updateUser - ' + _.status + ' - ' + _.statusText) : void 0
        )
        //map((response) => response.body)
      );
  }

  getUserAccountInfo(id: string) {
    return this.http
      .get<UserProfileDto>(this.userAccountInfoUri + '/' + id, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getUserAccountInfo - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response?.body)
      );
  }

  createInitialUserObj(claims: any): User {
    // Create Empty User Object to hold in State/App Code
    const userInfo: User = {
      id: '',
      userName: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      divisionId: '',
      reportsToId: '',
      phoneNumber: '',
      isActive: false,
      primaryRoleId: [],
    };
    // Decode claims from JWT for use in User object attributes

    for (const key in claims) {
      switch (true) {
        case key.includes('/name'):
          userInfo.emailAddress = `${claims[key]}`;
          break;
        case key.includes('/surname'):
          userInfo.lastName = `${claims[key]}`;
          break;
        case key.includes('/givenname'):
          userInfo.firstName = `${claims[key]}`;
          break;
        case key.includes('/serialnumber'):
          userInfo.id = `${claims[key]}`;
          break;
        case key.includes('/role'):
          // TODO:
          // If of type array
          if (claims[key] instanceof Array) {
            claims[key].forEach((role: string) => {
              userInfo.primaryRoleId?.push(`${role}` as never);
            });
          } else if (primaryRoleIdConfig.includes(claims[key])) {
            userInfo.primaryRoleId?.push(`${claims[key]}` as never);
          }
          break;
        default:
          break;
      }
    }

    return userInfo;
  }

  /**
   * Get the logged in User's Client information, including ClientID for use elsewhere in the application.
   * @public Can be called by components and services outside of this file.
   * @param {number} userId The UserID of the currently logged in user as a number.
   * @returns Observable HttpResponse containing the Client Information in the body of the response.
   * @throws {handleError} If there is an error response, this will throw the handleError function using the rxjs tap operator.
   */
  // public getClientInformation(userId: number): Observable<HttpResponse<any>> {
  //   const httpParams = new HttpParams().appendAll({
  //     usa_Id: userId,
  //   });
  //   return this.http
  //     .get<any>(this.getClientInfoUrl, {
  //       headers: this.getCustomHeaders(),
  //       observe: 'response',
  //       params: httpParams,
  //     })
  //     .pipe(
  //       tap((_) =>
  //         environment.showConsoleLogs
  //           ? console.log('called getClientInformation - ' + _.status + ' - ' + _.statusText)
  //           : void 0
  //       ),
  //       catchError(this.handleError<HttpResponse<any>>('getClientInformation'))
  //     );
  // }
}
