// Interfaces/Models
import { AppData } from '../../core/interfaces/store/app-data';

export const appDataFeatureKey = 'appData';

// App Data State interface
export interface State {
  appData: AppData | null;
  error: string;
  isLoading: boolean;
}

// Initial Display Statte
export const initialState: State = {
  appData: null,
  error: '',
  isLoading: false,
};
