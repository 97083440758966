// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
// rxjs
import { map, Observable, tap } from 'rxjs';
// Services
import { HttpHeaderService } from './http-header.service';
// environment
import { environment } from '../../../environments/environment';
// Interfaces
import { UserLoginDto } from '../interfaces/user/user-login-dto';
import { TokenResponse } from '../../features/login/interfaces/token-response';
import { ForgotPasswordDto } from '../interfaces/user/forgot-password-dto';
import { ForgotPasswordTokenResponse } from '../../features/login/interfaces/forgot-password-token-response';
import { ResetPasswordDto } from '../interfaces/user/reset-password-dto';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  loginUri = environment.baseUri + '/api/Auth/login';
  forgotPasswordUri = environment.baseUri + '/api/Auth/ForgotPassword';
  resetPasswordUri = environment.baseUri + '/api/Auth/ResetPassword';

  constructor(private http: HttpClient, private httpHeaderService: HttpHeaderService) {}

  login(userLoginInfo: UserLoginDto): Observable<TokenResponse> {
    return this.http
      .post(this.loginUri, userLoginInfo, {
        headers: this.httpHeaderService.getNoAuthHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs ? console.log('called login - ' + _.status + ' - ' + _.statusText) : void 0
        ),
        map((response) => response.body as TokenResponse)
      );
  }

  forgotPassword(forgotPasswordDto: ForgotPasswordDto): Observable<ForgotPasswordTokenResponse> {
    return this.http
      .post(this.forgotPasswordUri, forgotPasswordDto, {
        headers: this.httpHeaderService.getNoAuthHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called forgotPassword - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body as ForgotPasswordTokenResponse)
      );
  }

  resetPassword(resetPasswordDto: ResetPasswordDto): Observable<HttpResponse<unknown>> {
    return this.http
      .post(this.resetPasswordUri, resetPasswordDto, {
        headers: this.httpHeaderService.getNoAuthHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called resetPassword - ' + _.status + ' - ' + _.statusText)
            : void 0
        )
        //map((response) => response.body as ResetPasswordResponse)
      );
  }
}
