// Interfaces/Models
import { Display } from '../../core/interfaces/store/display';

export const displayFeatureKey = 'display';

// Display State Interface
export interface State {
  display: Display;
  error: string;
  isLoading: boolean;
}

// Initial Display Statte
export const initialState: State = {
  display: { sideNavVisible: false },
  error: '',
  isLoading: true,
};
