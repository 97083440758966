// NgRx
import { createAction, props } from '@ngrx/store';
// Models/Interfaces
import { UserLoginDto } from '../../core/interfaces/user/user-login-dto';
import { TokenDetails } from '../../core/interfaces/store/token-details';
import { TokenResponse } from './../../features/login/interfaces/token-response';
import { JwtPayload } from 'jwt-decode';

export const loadTokenFromUIRequested = createAction(
  '[Token] Load Token From UI Requested',
  props<{ userLogin: UserLoginDto }>()
);

export const loadTokenRequested = createAction('[Token] Load Token Requested', props<{ userLogin: UserLoginDto }>());

export const loadTokenRequestedBeginUserLoad = createAction(
  '[Token] Load Token Requested - Begin User Load',
  props<{ userLogin: UserLoginDto }>()
);

export const loadTokenSucceeded = createAction(
  '[Token] Load Token Succeeded',
  props<{ tokenResponse: TokenResponse }>()
);

export const loadTokenFailed = createAction('[Token] Load Token Failed', props<{ error: string }>());

export const tokenDecodedSuccess = createAction(
  '[Token] Token Decode Succeeded',
  props<{ tokenDetails: TokenDetails; decodedTokenObj: JwtPayload }>()
);

export const forceLogOut = createAction('[Token] Force Token Removal For Log Out/Inactive');

export const refreshFlowRequested = createAction(
  '[Token] Refresh Flow Requested',
  props<{ tokenDetails: TokenDetails }>()
);

export const refreshFlowSucceeded = createAction('[Token] Refresh Flow Succeeded');
