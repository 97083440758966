// Angular Imports
import {Component} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModal, NgbModalConfig, NgbTypeaheadModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
// Ag-Grid
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
// rxjs
import {
  Subject,
  takeUntil,
} from 'rxjs';

// Services
import { AdminService } from '../../../core/services/admin.service';
import { ErrorHandlingService } from '../../../core/services/error-handling.service';

// Modals
import { SimpleTextModalComponent } from '../../../shared/modals/simple-text-modal/simple-text-modal.component';

@Component({
  selector: 'rts-delete-button-row-cell-renderer',
  standalone: true,
  imports: [CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,FormsModule
    ],
  templateUrl: './delete-button-row-cell-renderer.component.html',
  styleUrls: ['./delete-button-row-cell-renderer.component.scss'],
})
export class DeleteButtonCellRendererComponent implements ICellRendererAngularComp {
  private unsubscribe$ = new Subject<void>();
  params!: ICellRendererParams;
  dateValue: Date | null = null; // Variable to hold the date value
  constructor(
    private modalService: NgbModal,
    config: NgbModalConfig,
    private adminService: AdminService,
    private errorService: ErrorHandlingService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    config.size = 'sm';
    config.centered = true;

  }

  // Mandatory - Params for rendering
  agInit(params: ICellRendererParams): void {
    //console.log(params);
    this.params = params;
    this.dateValue = params.data.date;
  }

  onSaveClick(): void {
    this.adminService
      .updateScheduledQuote(this.params.data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          const modalRef = this.modalService.open(SimpleTextModalComponent, {
            centered: true,
            size: 'sm',
          });
          modalRef.componentInstance.headerText = 'Success';
          modalRef.componentInstance.bodyTextEntries = ['Scheduled Quote Updated'];
        },
        error: (e) => {
          this.errorService.onGeneralError(e);
        },
      });
  }


  onDeleteClick(): void {
    this.adminService
      .deleteScheduledQuote(this.params.data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          //console.log(result);
          //this.store.dispatch(displayStoreActions.hideDisplayLoadingIndicator());
          const modalRef = this.modalService.open(SimpleTextModalComponent, {
            centered: true,
            size: 'sm',
          });
          modalRef.componentInstance.headerText = 'Success';
          modalRef.componentInstance.bodyTextEntries = ['Scheduled Quote Deleted'];
        },
        error: (e) => {
          this.errorService.onGeneralError(e);
        },
      });

  }
  refreshCell() {
    // Refresh the current cell
    this.params.api.refreshCells({ rowNodes: [this.params.node], force: true });
  }


  // Mandatory - Return true if you have managed the cell refresh yourself in this method, otherwise return false.
  // If you return false, the grid will remove the component from the DOM and create a new component in its place
  // with the new values.
  refresh(params: ICellRendererParams): boolean {

    return false;
  }
}
