// Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Guards
import { loginGuard } from './core/guards/login.guard';
import { authGuard } from './core/guards/auth.guard';
// Resolvers
import { RegisterResolver } from './features/register/register.resolver';

const routes: Routes = [
  // Blank route redirect
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./features/login/login.routes'),
    canActivate: [loginGuard],
  },
  {
    path: 'register',
    loadComponent: () => import('./features/register/register.component').then((mod) => mod.RegisterComponent),
    canActivate: [authGuard],
    resolve: {
      pageData: RegisterResolver,
    },
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./features/dashboard/dashboard.component').then((mod) => mod.DashboardComponent),
    canActivate: [authGuard],
  },
  {
    path: 'goals',
    loadChildren: () => import('./features/goals/goals.routes'),
    canActivate: [authGuard],
  },
  {
    path: 'profile',
    loadComponent: () => import('./features/profile/profile.component').then((mod) => mod.ProfileComponent),
    canActivate: [authGuard],
  },
  {
    path: 'admin',
    loadComponent: () => import('./features/admin/admin.component').then((mod) => mod.AdminComponent),
    canActivate: [authGuard],
  },
  {
    path: 'desk-log',
    loadComponent: () => import('./features/desk-log/desk-log.component').then((mod) => mod.DeskLogComponent),
    canActivate: [authGuard],
  },
  {
    path: 'vehicle-sale/:id',
    loadComponent: () =>
      import('./features/vehicle-sale/vehicle-sale.component').then((mod) => mod.VehicleSaleComponent),
    canActivate: [authGuard],
  },
  {
    path: 'vehicle-sale',
    loadComponent: () =>
      import('./features/vehicle-sale/vehicle-sale.component').then((mod) => mod.VehicleSaleComponent),
    canActivate: [authGuard],
  },
  {
    path: 'forecasting',
    loadComponent: () => import('./features/forecasting/forecasting.component').then((mod) => mod.ForecastingComponent),
    canActivate: [authGuard],
  },
  {
    path: 'desk-log-summary',
    loadComponent: () =>
      import('./features/desk-log-summary/desk-log-summary.component').then((mod) => mod.DeskLogSummaryComponent),
    canActivate: [authGuard],
  },
  {
    path: 'variable-operations',
    loadComponent: () =>
      import('./features/variable-operations/variable-operations.component').then((mod) => mod.VariableOperationsComponent),
    canActivate: [authGuard],
  },
  {
    path: 'work-days',
    loadChildren: () => import('./features/work-days/work-days.routes'),
    canActivate: [authGuard],
  },
  // {
  //   path: 'user-data',
  //   loadChildren: () =>
  //     import('./features/goals/components/goals-user/goals-user.component').then(
  //       (mod) => mod.GoalsUserComponent
  //     ),
  //   canActivate: [authGuard],
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
