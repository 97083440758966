// NgRx Imports
import { createAction, props } from '@ngrx/store';
// Interfaces/Models
import { User } from '../../core/interfaces/store/user';
import { UserLoginDto } from '../../core/interfaces/user/user-login-dto';
import { UserProfileDto } from '../../core/interfaces/user/user-profile-dto';

export const loadUserRequested = createAction('[User] Load User Requested');

export const loadUserSucceeded = createAction('[User] Load User Succeeded', props<{ userAccount: User }>());

export const loadUserAccountInfoSucceeded = createAction(
  '[User] Load User Account Info Succeeded',
  props<{ userInfo: UserProfileDto }>()
);

export const postAuthRedirectSuccess = createAction('[User] Post Auth Redirect Successfull');

export const loadUserFailed = createAction('[User] Load User Failed', props<{ error: string }>());

export const startUserLoad = createAction('[User] Start User Loading Indicator', props<{ userLogin: UserLoginDto }>());

export const forceUserLoadingFlag = createAction('[User] Force Start User Loading Indicator');

export const forceLogOutClearUser = createAction('[User] Force Clear User Info For Log Out/Inactive');

export const logOutSuccess = createAction('[User] Log Out Success');
