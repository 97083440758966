// Angular Imports
import { Injectable } from '@angular/core';
// NgRx Imports
import { Store } from '@ngrx/store';
// Interfaces
import { TokenDetails } from '../interfaces/store/token-details';
import { TokenResponse } from '../../features/login/interfaces/token-response';
// Services
import { JwtTokenService } from './jwt-token.service';
// Actions
import * as TokenActions from '../../root-store/token-store/token.actions';

const R2S_AUTH_TOKEN_STORAGE = 'r2s-auth-storage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private tokenService: JwtTokenService, private store: Store) {}

  getTokensFromStorageAsResponse() {
    const tokenDetails = this.getToken();
    const tokenResponseObj: TokenResponse = {
      auth: {
        bearer: tokenDetails?.bearer || '',
        refresh: tokenDetails?.refresh || '',
      },
    };

    return tokenResponseObj;
  }

  public isTokenInLocalStorageAndValid(): boolean {
    // 1. get token details from local storage
    const tokenDetails = this.getToken();
    // token does not exist in local storage;
    if (!tokenDetails) {
      return false;
    } else if (this.tokenService.isTokenExpired(this.tokenService.decodeToken(tokenDetails?.bearer))) {
      // Token exists but is expired, kick off refresh flow
      console.log('TOKEN EXPIRED');
      // Dispatch action to tokenStore to update State that current token is expired, and refresh token flow is now underway
      const props = {
        tokenDetails: tokenDetails,
      };
      this.store.dispatch(TokenActions.refreshFlowRequested(props));
      return false;
    } else {
      // Token exists in local storage and is not expired.
      return true;
    }
  }

  public saveToken(tokenDetails: TokenDetails): void {
    window.sessionStorage.removeItem(R2S_AUTH_TOKEN_STORAGE);
    window.localStorage.removeItem(R2S_AUTH_TOKEN_STORAGE);
    window.localStorage.setItem(R2S_AUTH_TOKEN_STORAGE, JSON.stringify(tokenDetails));
  }

  public getToken(): TokenDetails | null {
    const tokenDetails = window.localStorage.getItem(R2S_AUTH_TOKEN_STORAGE);
    if (tokenDetails) {
      return JSON.parse(tokenDetails);
    }

    return null;
  }

  clean(): void {
    window.localStorage.removeItem(R2S_AUTH_TOKEN_STORAGE);
    window.localStorage.removeItem(R2S_AUTH_TOKEN_STORAGE);
  }
}
