import { EMPTY } from 'rxjs';
// Angular Imports
import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router, CanActivateChildFn, CanLoadFn } from '@angular/router';
// NgRx
import { Store } from '@ngrx/store';
// Services
import { StorageService } from '../services/storage.service';
import { JwtTokenService } from '../services/jwt-token.service';
// Actions
import * as TokenActions from '../../root-store/token-store/token.actions';
import * as UserActions from '../../root-store/user-store/user.actions';

export const authGuard: CanMatchFn | CanActivateFn | CanActivateChildFn | CanLoadFn = () => {
  const store = inject(Store);
  const tokenService = inject(JwtTokenService);
  const storageService = inject(StorageService);
  const router = inject(Router);

  if (tokenService.isTokenInAppCodeAndValid()) {
    if (storageService.isTokenInLocalStorageAndValid()) {
      // Token exists in local storage and app code, user is logged in, allow access to protected route
      return true;
    } else {
      // token is in app code but not in local storage, save token to local storage from app code
      const tokenResponseObj = tokenService.getTokensAsResponse();
      store.dispatch(UserActions.forceUserLoadingFlag());
      store.dispatch(TokenActions.loadTokenSucceeded({ tokenResponse: tokenResponseObj }));
      return true;
    }
  } else if (storageService.isTokenInLocalStorageAndValid()) {
    // token is NOT in app code but is in local storage, save token app code from local storage
    const tokenResponseObj = storageService.getTokensFromStorageAsResponse();
    store.dispatch(UserActions.forceUserLoadingFlag());
    store.dispatch(TokenActions.loadTokenSucceeded({ tokenResponse: tokenResponseObj }));
    return true;
  }
  // token is not in app code or local storage, user can access login page only
  // Redirect to the login page with extras
  //return router.createUrlTree(['/login']);
  router.navigate(['login']);
  return EMPTY;
};
