// Angular Imports
import { Injectable } from '@angular/core';
// rxjs
import { Observable, Subject, takeUntil } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// Store
import { UserStoreSelectors } from '../../root-store';
// Interfaces
import { UserProfileDto } from '../interfaces/user/user-profile-dto';
import { primaryRoleIdConfigData } from '../interfaces/user/user-profile-dto';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService {
  private unsubscribe$ = new Subject<void>();
  user$: Observable<UserProfileDto | null>;
  userValue: UserProfileDto | null = null;
  isUserLoaded$: Observable<boolean>;
  isUserLoadedValue = false;

  constructor(private store: Store) {
    this.user$ = this.store.select(UserStoreSelectors.selectUser);
    this.isUserLoaded$ = this.store.select(UserStoreSelectors.selectUserIsFullLoaded);
    this.isUserLoaded$.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      this.isUserLoadedValue = result;
      if (this.isUserLoadedValue) {
        this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
          this.userValue = result;
        });
      }
    });
  }

  isSiteAdmin(userValue: UserProfileDto | null = this.userValue): boolean {
    if (this.isUserLoadedValue) {
      return userValue?.primaryRoleId?.includes(primaryRoleIdConfigData.site_admin as never) || false;
    } else {
      return false;
    }
  }

  isR2sAdmin(userValue: UserProfileDto | null = this.userValue): boolean {
    if (this.isUserLoadedValue) {
      return (
        userValue?.primaryRoleId?.includes(primaryRoleIdConfigData.r2s_admin as never) || this.isSiteAdmin() || false
      );
    } else {
      return false;
    }
  }

  isUserManager(userValue: UserProfileDto | null = this.userValue): boolean {
    if (this.isUserLoadedValue) {
      return (
        userValue?.primaryRoleId?.includes(primaryRoleIdConfigData.user_manager as never) || this.isSiteAdmin() || false
      );
    } else {
      return false;
    }
  }

  isTaskUser(userValue: UserProfileDto | null = this.userValue): boolean {
    if (this.isUserLoadedValue) {
      return (
        userValue?.primaryRoleId?.includes(primaryRoleIdConfigData.r2s_user as never) || this.isSiteAdmin() || false
      );
    } else {
      return false;
    }
  }

  isTaskManager(userValue: UserProfileDto | null = this.userValue): boolean {
    if (this.isUserLoadedValue) {
      return (
        userValue?.primaryRoleId?.includes(primaryRoleIdConfigData.task_manager as never) || this.isSiteAdmin() || false
      );
    } else {
      return false;
    }
  }

  isAuthManager(userValue: UserProfileDto | null = this.userValue): boolean {
    return this.isSiteAdmin(userValue) || this.isR2sAdmin(userValue) || this.isUserManager(userValue) || false;
  }

  isAuthAdmin(userValue: UserProfileDto | null = this.userValue): boolean {
    return this.isSiteAdmin(userValue) || this.isR2sAdmin(userValue) || false;
  }
}
