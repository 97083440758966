// NgRx
import { createAction, props } from '@ngrx/store';

import { AppData } from '../../core/interfaces/store/app-data';

export const loadAppDataRequested = createAction('[App-Data] Load App Data Requested');

export const loadAppDataCompleted = createAction('[App-Data] Load App Data Completed', props<{ appData: AppData }>());

export const loadAppDataFailed = createAction('[App-Data] Load App DataFailed', props<{ error: string }>());
