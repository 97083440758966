
// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// rxjs
import { map, Observable, Subject, tap } from 'rxjs';
// Services
import { HttpHeaderService } from './http-header.service';
// environment
import { environment } from '../../../environments/environment';
// Interfaces
import { AssignToType } from '../interfaces/tasks/core/assign-to-type';
import { TaskOriginationType } from '../interfaces/tasks/core/task-origination-type';
import { TaskType } from '../interfaces/tasks/core/task-type';
import { TaskFrequency } from '../interfaces/tasks/core/task-frequency';
import { TaskAssignmentLevel } from '../interfaces/roles/task-assignment-level';
import { DivisionDto } from '../interfaces/divisions/division-dto';
import { DepartmentDto } from '../interfaces/departments/department-dto';
import { UserLookupProfileDto } from '../interfaces/user/user-lookup-profile-dto';
import { UserLookupCriteriaDto } from './../interfaces/user/user-lookup-criteria-dto';
import { VehicleType } from '../interfaces/vehicle/vehicle-type';
import { EmployeeRoleDto } from '../interfaces/roles/employee-role-dto';
import { VehicleClassificationType } from '../interfaces/vehicle-sale/vehicle-classification-type';
import { VehicleStatusDto } from '../interfaces/vehicle-sale/vehicle-status-dto';
import { WarrantyTypeDto } from '../interfaces/vehicle-sale/warranty-type-dto';
import { FundingStatusDto } from '../interfaces/vehicle-sale/funding-status-dto';
import { FuelTypeDto } from '../interfaces/vehicle-sale/fuel-type-dto';
import { DivisionLendersDto } from '../interfaces/vehicle-sale/division-lenders-dto';
import { SimpleLenderDto } from '../interfaces/vehicle-sale/simple-lender-dto';




@Injectable({
  providedIn: 'root',
})
export class LookupService {
  getAssignToTypesUri = environment.baseUri + '/api/Lookups/AssignToTypes';
  getTaskOriginationTypesUri = environment.baseUri + '/api/Lookups/TaskOriginationTypes';
  getTaskTypesUri = environment.baseUri + '/api/Lookups/TaskTypes';
  getTaskFrequenciesUri = environment.baseUri + '/api/Lookups/TaskFrequncies';
  getTaskAssignmentLevelsUri = environment.baseUri + '/api/Lookups/TaskAssignmentLevels';
  getDivisionsUri = environment.baseUri + '/api/Lookups/Divisions';
  getDepartmentsViaDivisionsUri = environment.baseUri + '/api/Lookups/Division';
  getAllDepartmentsUri = environment.baseUri + '/api/Company/Departments';
  lookupEmployeeListUri = environment.baseUri + '/api/Company/Employees';
  lookupSalesPersonsUri = environment.baseUri + '/api/Company/EmployeesByRoleType/SalesPersons';
  lookupSalesManagersUri = environment.baseUri + '/api/Company/EmployeesByRoleType/SalesManagers';
  lookupBusinessManagersUri = environment.baseUri + '/api/Company/EmployeesByRoleType/BusinessManagers';
  lookupBdcRepsUri = environment.baseUri + '/api/Company/EmployeesByRoleType/BDCRepresentatives';
  lookupVepRepsUri = environment.baseUri + '/api/Company/EmployeesByRoleType/VEPRepresentatives';
  lookupVehicleClassificationTypesUri = environment.baseUri + '/api/Lookups/VehicleClassificationTypes';
  lookupVehicleSaleStatusUri = environment.baseUri + '/api/Lookups/VehicleStatuses';
  lookupVehicleTypesUri = environment.baseUri + '/api/Lookups/VehicleTypes';
  lookupFuelTypesUri = environment.baseUri + '/api/Lookups/FuelTypes';

  lookupVehicleWarrantyTypesUri = environment.baseUri + '/api/Lookups/WarrantyTypes';
  lookupVehicleFundingStatusesUri = environment.baseUri + '/api/Lookups/FundingStatuses';
  getEmployeeRolesUri = environment.baseUri + '/api/Company/EmployeeRoles/All';
  getEmployeeRolesByDepartmentUri = environment.baseUri + '/api/Company/EmployeeRoles/Department/';

  getLendersByDivisionUri = environment.baseUri + '/api/Lookups/Division/';

  getDailyQuoteUri = environment.baseUri + '/api/';
  getAllDailyQuotesUri = environment.baseUri + '/api/';
  getGlobalAlertUri = environment.baseUri + '/api/';
  getAllGlobalAlertsUri = environment.baseUri + '/api/';

  //public vehicleTypesList: VehicleType[] = [];
  public vehicleTypeList$ = new Subject<VehicleType[]>();
  //public salesTypeList: SalesType[] = [];
  public salesTypeList$ = new Subject<VehicleClassificationType[]>();

  constructor(private http: HttpClient, private httpHeaderService: HttpHeaderService) {}

  updateVehicleTypeList(vehicleTypeList: VehicleType[]) {
    this.vehicleTypeList$.next(vehicleTypeList);
  }

  updateSalesTypeList(salesTypeList: VehicleClassificationType[]) {
    this.salesTypeList$.next(salesTypeList);
  }

  getEmployeeRolesByDepartment(departmentId: string): Observable<EmployeeRoleDto[] | null> {
    return this.http
      .get<EmployeeRoleDto[]>(this.getEmployeeRolesByDepartmentUri + departmentId, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getEmployeeRolesByDepartment - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  getEmployeeRoles(): Observable<EmployeeRoleDto[] | null> {
    return this.http
      .get<EmployeeRoleDto[]>(this.getEmployeeRolesUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getEmployeeRoles - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  lookupVehicleTypes(): Observable<VehicleType[] | null> {
    return this.http
      .get<VehicleType[]>(this.lookupVehicleTypesUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called lookupVehicleTypes - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  lookupFuelTypes(): Observable<FuelTypeDto[] | null> {
    return this.http
      .get<FuelTypeDto[]>(this.lookupFuelTypesUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called lookupFuelTypes - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  lookupVehicleWarrantyTypes(): Observable<WarrantyTypeDto[] | null> {
    return this.http
      .get<WarrantyTypeDto[]>(this.lookupVehicleWarrantyTypesUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called lookupVehicleWarrantyTypes - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  lookupVehicleFundingStatuses(): Observable<FundingStatusDto[] | null> {
    return this.http
      .get<FundingStatusDto[]>(this.lookupVehicleFundingStatusesUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called lookupVehicleFundingStatuses - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  lookupVehicleClassificationTypes(): Observable<VehicleClassificationType[] | null> {
    return this.http
      .get<VehicleClassificationType[]>(this.lookupVehicleClassificationTypesUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called lookupVehicleClassificationType - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  lookupVehicleSalesStatus(): Observable<VehicleStatusDto[] | null> {
    return this.http
      .get<VehicleStatusDto[]>(this.lookupVehicleSaleStatusUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called lookupVehicleStatusDto - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  lookupVepReps(): Observable<UserLookupProfileDto[] | null> {
    return this.http
      .get<UserLookupProfileDto[]>(this.lookupVepRepsUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called lookupVepReps - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  lookupBdcReps(): Observable<UserLookupProfileDto[] | null> {
    return this.http
      .get<UserLookupProfileDto[]>(this.lookupBdcRepsUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called lookupBdcReps - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  lookupBusinessManagers(): Observable<UserLookupProfileDto[] | null> {
    return this.http
      .get<UserLookupProfileDto[]>(this.lookupBusinessManagersUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called lookupBusinessManagers - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  lookupSalesManagers(): Observable<UserLookupProfileDto[] | null> {
    return this.http
      .get<UserLookupProfileDto[]>(this.lookupSalesManagersUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called lookupSalesManagers - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  lookupSalesPersons(): Observable<UserLookupProfileDto[] | null> {
    return this.http
      .get<UserLookupProfileDto[]>(this.lookupSalesPersonsUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called lookupSalesPersons - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  lookupEmployeeList(lookupCriteria: UserLookupCriteriaDto): Observable<UserLookupProfileDto[] | null> {
    return this.http
      .post<UserLookupProfileDto[]>(this.lookupEmployeeListUri, lookupCriteria, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called lookupEmployeeList - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  getAssignToTypes(): Observable<AssignToType[] | null> {
    return this.http
      .get<AssignToType[]>(this.getAssignToTypesUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getAssignToTypes - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  getTaskOriginationTypes(): Observable<TaskOriginationType[] | null> {
    return this.http
      .get<TaskOriginationType[]>(this.getTaskOriginationTypesUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getTaskOriginationTypes - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  getTaskTypes(): Observable<TaskType[] | null> {
    return this.http
      .get<TaskType[]>(this.getTaskTypesUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs ? console.log('called getUserTasks - ' + _.status + ' - ' + _.statusText) : void 0
        ),
        map((response) => response.body)
      );
  }

  getTaskFrequencies(): Observable<TaskFrequency[] | null> {
    return this.http
      .get<TaskFrequency[]>(this.getTaskFrequenciesUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getTaskFrequencies - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  getTaskAssignmentLevels(): Observable<TaskAssignmentLevel[] | null> {
    return this.http
      .get<TaskAssignmentLevel[]>(this.getTaskAssignmentLevelsUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getTaskAssignmentLevels - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  getDivisions(): Observable<DivisionDto[] | null> {
    return this.http
      .get<DivisionDto[]>(this.getDivisionsUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs ? console.log('called getDivisions - ' + _.status + ' - ' + _.statusText) : void 0
        ),
        map((response) => response.body)
      );
  }

  getDepartmentsViaDivision(divisionId: string): Observable<DepartmentDto[] | null> {
    return this.http
      .get<DepartmentDto[]>(this.getDepartmentsViaDivisionsUri + '/' + divisionId + '/' + 'Departments', {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getDepartmentsViaDivisions - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  getAllDepartments(): Observable<DepartmentDto[] | null> {
    return this.http
      .get<DepartmentDto[]>(this.getAllDepartmentsUri, {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getAllDepartments - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }

  getLendersViaDivision(divisionId: string, isActiveOnly: boolean): Observable<SimpleLenderDto[] | null> {
    return this.http
      .get<SimpleLenderDto[]>(this.getLendersByDivisionUri + divisionId + '/' + 'Lenders', {
        headers: this.httpHeaderService.getBearerHeaders(),
        observe: 'response',
        params: { isActiveOnly: isActiveOnly.toString() },
      })
      .pipe(
        tap((_) =>
          environment.showConsoleLogs
            ? console.log('called getLendersViaDivision - ' + _.status + ' - ' + _.statusText)
            : void 0
        ),
        map((response) => response.body)
      );
  }
}
