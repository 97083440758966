// NgRx Imports
import { createFeatureSelector, createSelector } from '@ngrx/store';
// State
import * as appDataState from './app-data.state';

const selectGetAppDataState = createFeatureSelector<appDataState.State>(appDataState.appDataFeatureKey);

export const selectAppDataDivisions = createSelector(selectGetAppDataState, (state) => state.appData?.divisions);

export const selectAppDataDepartments = createSelector(selectGetAppDataState, (state) => state.appData?.departments);

export const selectAppDataEmployeeRoles = createSelector(
  selectGetAppDataState,
  (state) => //state.appData?.employeeRoles
   state.appData?.employeeRoles?.sort((a, b) => (a?.roleName ?? '').localeCompare(b?.roleName ?? ''))

);

export const selectAppDataDailyQuote = createSelector(selectGetAppDataState, (state) => state.appData?.dailyQuote);

export const selectAppDataGlobalAlert = createSelector(selectGetAppDataState, (state) => state.appData?.globalAlert);

export const selectAppDataIsLoading = createSelector(selectGetAppDataState, (state) => state.isLoading);

// // export const selectAppDataUserMetrics = createSelector(selectGetAppDataState, (state) => state.appData);

// export const selectAppDataError = createSelector(selectGetAppDataState, (state) => state.error);

// export const selectAppDataAssignToTypes = createSelector(
//   selectGetAppDataState,
//   (state) => state.appData?.assignToTypes
// );

// export const selectAppDataTaskTypes = createSelector(
//   selectGetAppDataState,
//   (state) => state.appData?.taskTypes
// );

// export const selectAppDataTaskFrequencies = createSelector(
//   selectGetAppDataState,
//   (state) => state.appData?.taskFrequencies
// );

// export const selectAppDataTaskOriginationTypes = createSelector(
//   selectGetAppDataState,
//   (state) => state.appData?.taskOriginationTypes
// );

// export const selectAppDataTaskAssignmentLevels = createSelector(
//   selectGetAppDataState,
//   (state) => state.appData?.taskAssignmentLevels
// );
