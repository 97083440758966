import { DepartmentDto } from '../departments/department-dto';
import { DivisionDtoBase } from '../divisions/division-dto-base';
import { EmployeeRoleDto } from '../roles/employee-role-dto';

// export interface UserProfileDto {
//   id?: string | null;
//   userName?: string | null;
//   firstName?: string | null;
//   lastName?: string | null;
//   email?: string | null;
//   divisionId?: string | null;
//   reportsToId?: string | null;
//   phoneNumber?: string | null;
//   isActive?: boolean;
//   role?: string | null;
//   emplpyeeRoleId?: string | null;
//   departmentId?: string | null;
//   department?: DepartmentDto;
//   division?: DivisionDtoBase;
//   employeeRole?: EmployeeRoleDto;
// }

export interface UserProfileDto {
  id?: string | undefined;
  userName: string;
  firstName?: string | undefined;
  lastName?: string | undefined;
  email?: string | undefined;
  divisionId: string;
  reportsToId: string;
  phoneNumber: string;
  isActive: boolean;
  //role?: string | undefined;
  emplpyeeRoleId?: string | undefined;
  departmentId?: string | undefined;
  isR2SAdmin: boolean;
  isR2SUserManager: boolean;
  isUser: boolean;
  isSiteAdmin: boolean;
  department?: DepartmentDto;
  division?: DivisionDtoBase;
  employeeRole?: EmployeeRoleDto;
  primaryRoleId?:
    | ['site_admin' | 'r2s_admin' | 'user_manager' | 'r2s_user' | 'task_manager' | 'task_job_admin']
    | []
    | null;
  level: number;
}

export const primaryRoleIdConfigData = {
  site_admin: 'site_admin',
  r2s_admin: 'r2s_admin',
  user_manager: 'user_manager',
  r2s_user: 'r2s_user',
  task_manager: 'task_manager',
  task_job_admin: 'task_job_admin',
};
