// Angular Imports
import { Injectable } from '@angular/core';
// NgRx Imports
import { Actions, createEffect, ofType } from '@ngrx/effects';
// rxjs
import { map, tap } from 'rxjs';
// Actions
import * as displayActions from './display.actions';
import * as userActions from '../user-store/user.actions';
// Router Actions from NgRx
import { ROUTER_REQUEST, ROUTER_CANCEL, ROUTER_ERROR, ROUTER_NAVIGATED } from '@ngrx/router-store';

@Injectable()
export class DisplayStoreEffects {
  constructor(private actions$: Actions) {}

  forceShowLoadingIndicator$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_REQUEST),
      map(() => {
        //console.log('ROUTER REQUEST');
        return displayActions.forceShowLoadingIndicator();
      })
    );
  });

  // forceHideLoadingIndicator$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(ROUTER_NAVIGATED, ROUTER_CANCEL, ROUTER_ERROR),
  //     map(() => {
  //       //console.log('ROUTER NAVIGATED');
  //       return displayActions.hideDisplayLoadingIndicator();
  //     })
  //   );
  // });

  // TODO: Can delete this? Not sure we need an affect here, can be handled in the reducer.
  sideNavToggle$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(displayActions.toggleSideNav),
        tap(() => console.log('Side Nav Toggled'))
      );
    },
    { dispatch: false }
  );

  forceHideSideNavUserLogOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.forceLogOutClearUser),
      map(() => {
        return displayActions.forceSideNavCloseUserLogOut();
      })
    );
  });
}
